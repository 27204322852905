import React from 'react'
import Link from 'gatsby'
import * as styles from './self-details.module.css';
import gridded from '../images/mesh.svg';
import { SiKotlin } from '@react-icons/all-files/si/SiKotlin';
import { SiSwift } from '@react-icons/all-files/si/SiSwift';
import { SiFlutter } from '@react-icons/all-files/si/SiFlutter';
import { SiJavascript } from "@react-icons/all-files/si/SiJavascript";
import Layout from './layout';
const SelfDetails = ({ siteTitle }) => {

    return (
        <div className={styles.selfDetailsWrapper}>
            <div className={styles.companyInterestWrapper}>
                <div className={[styles.company, styles.companyFirstDiv]}>
                    <div>Company</div>
                    <div className={styles.companyName}>Systameta</div>
                    <div className={styles.oneLine}>
                        <div className={styles.availableDot}></div>
                        <div className={[styles.oneLine ,styles.leftMargin ,styles.allUpper]}><a href='https://www.upwork.com/freelancers/~01e2be41454c375c2e' target="_blank">Available for hire</a></div>
                    </div>
                </div>
                <div className={styles.company}>
                    <div>Focused on</div>
                    <div className={styles.companyName}>iOS, Android, Flutter, React</div>
                    <div><a href='https://github.com/dsp1589' target="_blank">github.com</a></div>
                </div>
            </div>
            <div className={styles.skills}>
                <div className={[styles.allUpper ,styles.skillTitle]}>Top Skills</div>
                <div className={styles.skillsHList}>
                    <div>
                        <img src={gridded}></img>
                        <span className={[styles.skillTitle, styles.swiftColor]}><SiSwift className={styles.iconSize} /></span>
                    </div>
                    <div>
                        <img src={gridded}></img>
                        <span className={styles.skillTitle, styles.kotlinColor}><SiKotlin className={styles.iconSize} /></span>
                    </div>
                    <div>
                        <img src={gridded}></img>
                        <span className={[styles.skillTitle, styles.flutterColor]}><SiFlutter className={styles.iconSize} /></span>
                    </div>
                    <div>
                        <img src={gridded}></img>
                        <span className={[styles.skillTitle , styles.jsColor]}><SiJavascript className={styles.iconSize} /></span>
                    </div>
                </div>
            </div>
            <div className={styles.aboutMeWrapper}>
                <div className={styles.aboutMePseudoWrapper}>
                    <div className={styles.aboutMe}>
                        Hi 👋, <br></br><br></br>
                        I write beautiful and performant mobile apps for iOS and android platform. Very good knowledge in building an app from scratch to release in app store and play store. Haveave built numerous mobile apps both in my FTE and as freelancer. I do freelancing if the app or feature is challenging and interesting. Coding hobbyist! Interested in learning new things daily. In freetime I do react and react native app just for fun. Love writing flutter apps because of its expressive syntax and development time saving nature. Have lead a small team of 6 members.
                    </div>
                </div>
            </div>
            <div className={styles.workHistoryWrapper}>
                <div className={styles.workHistoryTitle}>Work History</div>
                <div className={styles.history}>
                    <div className={styles.historyEntry}>
                        <span className={styles.dotDouble}></span>
                        <div className={styles.historyEntryTextContent}>
                            <h4>Lead iOS Developer</h4>
                            <h5 className="">Philips</h5>
                            <span className="">October-2020</span>
                        </div>
                    </div>
                    <div className={styles.historyEntry}>
                        <span className={styles.dotDouble}></span>
                        <div className={styles.historyEntryTextContent}>
                            <h4>Moneygram</h4>
                            <h5 className="">Senior Mobile Engineer</h5>
                            <span className="">February-2020 -> March-2020</span>
                        </div>
                    </div>
                    <div className={styles.historyEntry}>
                        <span className={styles.dotDouble}></span>
                        <div className={styles.historyEntryTextContent}>
                            <h4>Verizon Wireless</h4>
                            <h5 className="">Senior Mobile Engineer</h5>
                            <span className="">March-2018 -> January-2020</span>
                        </div>
                    </div>
                    <div className={styles.historyEntry}>
                        <span className={styles.dotDouble}></span>
                        <div className={styles.historyEntryTextContent}>
                            <h4>Lead Mobile Developer</h4>
                            <h5 className="">Transfast (now Mastercard)</h5>
                            <span className="">April-2015 -> March-2018</span>
                        </div>
                    </div>
                    <div className={styles.historyEntry}>
                        <span className={styles.dotDouble}></span>
                        <div className={styles.historyEntryTextContent}>
                            <h4>Automation Engineer</h4>
                            <h5 className="">Bank of America</h5>
                            <span className="">February-2014 -> September-2014</span>
                        </div>
                    </div>
                    <div className={styles.historyEntry}>
                        <span className={styles.dotDouble}></span>
                        <div className={styles.historyEntryTextContent}>
                            <h4>Technology Analyst</h4>
                            <h5 className="">Infosys Limited</h5>
                            <span className="">March-2011 -> February-2014</span>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default SelfDetails;
