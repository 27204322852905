// extracted by mini-css-extract-plugin
export var aaaaa = "self-module--aaaaa--kSMZ1";
export var container = "self-module--container--wQEVR";
export var icon_size = "self-module--icon_size--HdDjR";
export var inSameline = "self-module--in-sameline--8HKkD";
export var location_icon = "self-module--location_icon--4vpSd";
export var nameSpin = "self-module--nameSpin--rkXt1";
export var placeName = "self-module--place-name--lde+b";
export var profile_img_background = "self-module--profile_img_background--oClyp";
export var profile_photo = "self-module--profile_photo--BrArb";
export var profile_photo_wrap = "self-module--profile_photo_wrap--z+VGX";
export var relocation = "self-module--relocation--ib5YR";
export var selfBasic = "self-module--selfBasic--RYFnO";
export var selfWrapper = "self-module--selfWrapper--4CaJF";
export var spin = "self-module--spin--IPuBQ";
export var textVCenter = "self-module--text-v-center--R4N0e";