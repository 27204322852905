import React from 'react'
import Link from 'gatsby'
import * as styles from './self.module.css';
import me from '../images/me.jpg';
import { MdEditLocation } from '@react-icons/all-files/md/MdEditLocation';

const Self = ({ siteTitle }) => (
    <div className={styles.selfWrapper}>
        <div className={styles.selfBasic}>
            <div>Full stack developer</div>
            <div className={styles.name}>D<span id="aaaaa">ha</span>na</div>
            <div >
                {/* className={styles.profile_photo_wrap} */}
                <div className={styles.container}>
                    <img src={me} className={styles.profile_photo}></img>
                    <div className={styles.profile_img_background}></div>
                </div>
            </div>
            <div className={styles.inSameline}>
                <div className={[styles.location_icon,styles.inSameline]}><MdEditLocation className={styles.icon_size} /></div><div className={[styles.inSameline,styles.textVCenter,styles.placeName]}>Texas, USA</div>
            </div>
            <div className={styles.relocation}>Available for Relocation</div>
        </div>
    </div>
)

export default Self;
