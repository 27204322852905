// extracted by mini-css-extract-plugin
export var aboutMe = "self-details-module--about-me--pwDEg";
export var aboutMePseudoWrapper = "self-details-module--about-me-pseudo-wrapper--za+cr";
export var aboutMeWrapper = "self-details-module--about-me-wrapper--O9p43";
export var availableDot = "self-details-module--available-dot--nDm5g";
export var company = "self-details-module--company--JaUg8";
export var companyFirstDiv = "self-details-module--company-first-div--H0-Ue";
export var companyInterestWrapper = "self-details-module--company-interest-wrapper--ielg9";
export var companyName = "self-details-module--company-name--svgLA";
export var dot = "self-details-module--dot--Y+PVw";
export var dotDouble = "self-details-module--dot-double--yOZZX";
export var flutterColor = "self-details-module--flutter-color--K3pWL";
export var history = "self-details-module--history--DTTcM";
export var historyEntry = "self-details-module--history-entry--NX1Sp";
export var historyEntryTextContent = "self-details-module--history-entry-text-content--sRfvX";
export var iconSize = "self-details-module--icon-size--H+WNg";
export var jsColor = "self-details-module--js-color--yXgvS";
export var kotlinColor = "self-details-module--kotlin-color--JK1-3";
export var leftMargin = "self-details-module--left-margin--GckG9";
export var oneLine = "self-details-module--one-line--XSRw4";
export var selfDetailsWrapper = "self-details-module--selfDetailsWrapper--EbLqz";
export var skillTitle = "self-details-module--skill-title--bhbvX";
export var skills = "self-details-module--skills--P1fH-";
export var skillsHList = "self-details-module--skills-h-list--bp3pI";
export var swiftColor = "self-details-module--swift-color--RLlrM";
export var workHistoryTitle = "self-details-module--work-history-title--z7bY-";
export var workHistoryWrapper = "self-details-module--work-history-wrapper--04Q9J";